<template>
  <div class="p-wrapper mobile">
    <div class="p-content">
      <div class="markdown-body">
        <h1 class="title"><strong>《未成年人隐私保护指引》</strong></h1>
        <p>更新日期：2024年 1月8日</p>
        <br/>
        <p>欢迎您选择由【上海域起网络科技有限公司】（包括但不限于其相关工作室，以下统称“公司”或“我们”）提供的游戏产品及服务除《隐私政策》外，我们还将通过《未成年人隐私保护指引》（“本指引”）
          帮助您和孩子（本指引中的“孩子”，是指不满十四周岁的未成年人）进一步了解我们收集、使用、存储和共享您孩子个人信息的情况，以及您和您的孩子所享有的相关权利。</p>
        <br/>
        <p>【重要提示】：</p>
        <br/>
        <p>
          本指引以及《隐私政策》同时适用于不满十四周岁的未成年人的个人信息处理。有关您和已满十四周岁的用户的个人信息处理，请查看《隐私政策》了解相关信息。我们会通过页面提示、交互流程、网站公告等方式向您说明未成年人个人信息收集等情况，并征得您的同意。请您仔细阅读、充分理解《隐私政策》和本指引后，选择是否同意前述隐私政策。</p>
        <br/>
        <p>1.我们收集的未成年人个人信息</p>
        <br/>
        <p>
          在您的孩子使用公司服务的过程中，公司仅会收集您同意我们收集的或您主动提供的有关您孩子的个人信息，以向您的孩子提供、优化我们的服务以及保障您孩子的账户安全。我们可能会收集的您孩子的个人信息的详情，请参见《隐私政策》。</p>
        <br/>
        <p>
          同时，为验证您与您孩子的监护关系，我们可能还会收集您的联系方式及其他必需的个人信息（如手机号码、电子邮箱等）来帮助我们判断确认您和您孩子的监护关系。我们承诺：为验证监护关系收集的您的个人信息将在监护关系验证完毕后予以删除。</p>
        <br/>
        <p>
          目前，除了向第三方调研机构收集游戏调研信息，以帮助我们改进游戏产品以及提供相关服务之外，我们不会主动从第三方获取您孩子的个人信息。如未来为业务发展需要从第三方间接获取并处理您孩子的个人信息的，我们会严格遵守相关法律法规的规定，要求该第三方征得您的同意并确保其提供的信息的合法性。</p>
        <br/>
        <p>2.我们如何使用未成年人个人信息</p>
        <br/>
        <p>
          我们严格遵守法律法规的规定以及与用户的约定，按照本指引及《隐私政策》所述使用收集的信息，以向您的孩子提供更为优质的服务。</p>
        <br/>
        <p>
          有关我们使用未成年人个人信息的方式详情请参见《隐私政策》，若我们使用您孩子的个人信息，超出了与收集时所声称的目的及具有直接或合理关联的范围，我们将在使用您孩子的个人信息前，再次向您告知并征得您的同意。</p>
        <br/>
        <p>3.未成年人个人信息的存储</p>
        <br/>
        <p>3.1
          我们按照法律法规规定，将在中华人民共和国境内收集到的您孩子的个人信息存储于中华人民共和国境内（不包括台湾、香港及澳门地区），并依法对这些信息进行严格保密。</p>
        <br/>
        <p>
          如果您孩子使用的游戏是由境外开发商或相关方（以下称“境外接收方”）开发或提供，并由我们在国内代理发行运营的，为了进行游戏安全分析，完善游戏服务，我们可能会将根据本指引收集您孩子的相关个人信息提供给该境外接收方。我们在向境外接收方提供您孩子个人信息前会根据法律规定取得您的单独同意并告知您有关个人信息跨境提供的详细规则，您也可以在产品中查看该产品个人信息跨境处理规则详情。</p>
        <br/>
        <p>3.2
          一般情况下，我们只会在为实现服务目的所必需的时间内或法律法规规定的条件下存储您孩子的个人信息。超出法律法规或监管规定的期限后，我们会按照法律法规的要求对您孩子的个人信息进行删除或者匿名化处理。</p>
        <br/>
        <p>4.未成年人个人信息的安全</p>
        <br/>
        <p>4.1
          为了保障您孩子的信息安全，我们会在现有技术水平下采取合理必要的措施来保护孩子的信息，采取物理防护、安全技术、管理制度等措施来降低丢失、误用、非授权访问、披露和更改的风险，包括但不限于数据加密传输、防火墙和加密存储、物理访问控制以及信息访问授权控制。为此我们设置了安全程序保护您孩子的信息不会被未经授权的访问所窃取，所有的个人信息被加密储存并放置于经防火墙严格保护的内部系统。</p>
        <br/>
        <p>4.2
          为了保障您孩子的信息安全，我们建立了专门的管理制度、流程和组织以保障信息的安全。例如，我们严格限制访问信息的人员范围，要求他们遵守保密义务，并进行审计。</p>
        <br/>
        <p>4.3
          若发生未成年人个人信息泄露等安全事件，我们会启动应急预案，阻止安全事件扩大，并及时以公告、推送通知或邮件等形式告知您和孩子安全事件的基本情况、我们即将或已经采取的处置措施和补救措施，以及我们对您的应对建议。如果难以实现逐一告知，我们将通过公告等方式发布警示。</p>
        <br/>
        <p>5.未成年人个人信息的更正</p>
        <br/>
        <p>
          在您孩子使用我们产品或服务期间，我们可能会视产品具体情况为您和您孩子提供相应的操作设置，以便您可以查询、复制、更正、补充、删除或撤回您孩子的相关个人信息，有关详情请参见《隐私政策》相关规定。您也可以联系我们进行咨询和处理。</p>
        <br/>
        <p>6.未成年人个人信息的披露</p>
        <br/>
        <p>除法律、行政法规另有规定，国家有权机关另有要求，为维护社会公共利益，为履行您/您孩子与我们签署的有关本产品或服务协议所必需，或为保护您/您孩子及其他用户人身、财产安全披露您孩子的个人信息的，
          我们不会披露孩子的个人信息。</p>
        <br/>
        <p>7.变更</p>
        <br/>
        <p>
          我们可能会适时对本指引进行修订。当本指引的条款发生变更时，我们会在版本更新时以适当的方式向您提示变更后的指引。请您仔细阅读变更后的隐私保护指引或指引内容，您的孩子继续使用公司服务表示您同意我们按照更新后的隐私保护指引收集、处理或使用您孩子的个人信息。</p>
        <br/>
        <p>8.其他</p>
        <br/>
        <p>
          《隐私政策》是公司服务统一适用的一般性隐私条款，其中所规定的内容包括但不限于用户权利及信息安全保障措施等均适用于使用公司服务之用户。本指引是专门针对未成年人的隐私保护指引，包含对于未成年人个人信息的特殊保护。如《隐私政策》与本指引存在不一致或矛盾之处，请以本指引为准。</p>
        <br/>
        <p>9.联系我们</p>
        <br/>
        <p>
          若您和孩子对本指引存在任何疑问，或者任何相关的投诉、意见或请求，请通过拨打客服电话400-903-1158与我们联系。我们将尽快审核所涉问题，并在验证身份后及时处理，最长在不超过十五个工作日或法律法规规定期限内予以回复。</p>
        <br/></div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    document.documentElement.scrollTop = 0
    document.body.scrollTop = 0
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/privacy.scss";
</style>
